const KEY = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: ' ',
  TAB: 'Tab',
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown',
  HOME: 'Home',
  END: 'End',
  META: 'Meta',
  ALT: 'Alt',
  CONTROL: 'Control',
  SHIFT: 'Shift',
  CAPS_LOCK: 'CapsLock',
};

export default KEY;
