import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getHeaderSearchGuestsFilter } from '@/redux/selectors/globalHeader';

import { FilterButton } from './FilterButton';

type TGuestsFilterButtonProps = {
  toggleFilter: () => void;
  className?: string;
};

export const GuestsFilterButton = ({ toggleFilter, className }: TGuestsFilterButtonProps) => {
  const intl = useIntl();

  const guests = useSelector(getHeaderSearchGuestsFilter);
  const totalGuests = (guests?.adults || 0) + (guests?.children || 0);

  return (
    <FilterButton
      aria-label={intl.formatMessage(
        {
          defaultMessage: 'Click to {hasValue, select, true {change the} other {add}} guests',
          id: 'r4iLf5',
          description: 'Home Search Widget > Guests Filter Aria Label',
        },
        {
          hasValue: !!totalGuests,
        },
      )}
      label={intl.formatMessage({
        defaultMessage: 'Guests',
        id: 'p7nvv8',
        description: 'Home Search Widget > Guests Filter Label',
      })}
      value={
        totalGuests
          ? intl.formatMessage(
              {
                defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
                id: '5Y37yc',
                description: 'Home Search Widget > Guests Filter Selected Value',
              },
              {
                totalGuests,
              },
            )
          : intl.formatMessage({
              defaultMessage: 'Who‘s going?',
              id: 'Q5zxXu',
              description: 'Home Search Widget > Guests Filter Placeholder',
            })
      }
      onClick={toggleFilter}
      className={className}
    />
  );
};
