import { useIntl } from 'react-intl';

import HomepageBanner from '@/assets/home/homepage-banner.jpg';
import HomepageBannerMobile from '@/assets/home/homepage-banner-mobile.jpg';
import HomepageThumbnail from '@/assets/home/homepage-social-media-thumbnail.jpg';

export default function useDefaultHomePageAndMetaValues() {
  const intl = useIntl();

  // when updating homepage banner and title we need to make sure
  // to also update the default values shown in social media sharing links
  return {
    defaultTitle: intl.formatMessage({
      defaultMessage: 'RV Rentals: Explore the Open Road in Comfort',
      id: 'LQBwbZ',
    }),
    defaultThumbnail: HomepageThumbnail,
    homepageBanner: HomepageBanner,
    homepageBannerMobile: HomepageBannerMobile,
  };
}
