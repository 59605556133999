import { Button, EHeadingStyleVariant, Heading } from '@outdoorsyco/bonfire';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import querystring from 'query-string';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getRentals } from '@/actions/rentals';
import { EHeaderTab } from '@/components/ui/SearchHeader/UniversalSearch/UniversalSearchContainer/menu';
import { ESearchFilters } from '@/constants/searchFilters';
import { useRouter } from '@/hooks/useRouter';
import { getUserCurrency } from '@/redux/selectors/currency';
import { getRentalsAreFetched, getRentalsTiles } from '@/redux/selectors/listing/similarRentals';
import { EListingSource } from '@/services/analytics/listings/types';
import { trackRenterSearchedEvent } from '@/services/analytics/search';
import { ERentalType } from '@/services/analytics/types';
import { EAlgoSlugVariationKey } from '@/services/experiments';
import { LocationGroupType } from '@/utility/surfacedLocation';

import css from './DynamicRentalsCarousel.module.css';
import { RentalsCarousel } from './RentalsCarousel';
import { useRandomRentalsTiles } from './useRandomRentalsTiles';

dayjs.extend(weekday);

export const DynamicRentalsCarousel = ({ eventSource }: { eventSource: EListingSource }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const router = useRouter();

  const userCurrency = useSelector(getUserCurrency);
  const rentalsAreFetched = useSelector(getRentalsAreFetched);
  const rentalsList = useSelector(getRentalsTiles);

  const hasNoWeekendRentals = rentalsAreFetched && !rentalsList.length;
  const randomRentalsList = useRandomRentalsTiles(!hasNoWeekendRentals);

  useEffect(() => {
    dispatch(getRentals({ count: 9, weekend: true }));
  }, [dispatch, userCurrency]);

  const handleViewAllRentals = async (weekend?: boolean) => {
    await trackRenterSearchedEvent({
      surfacedLocations: LocationGroupType.OTHER,
      totalAdults: null,
      totalChildren: null,
      totalInfants: null,
      locationQuery: null,
      source: 'home_weekend_rentals',
      isADAAccessible: false,
      isPetFriendly: false,
      flexibleDateRange: null,
      departureDate: null,
      returnDate: null,
      hasCategories: false,
      selectedCategory: EHeaderTab.ALL,
      towableYear: null,
      towableMake: null,
      towableModel: null,
      rentalType: ERentalType.RV,
      searchFilterCategory: ERentalType.RV,
      lastQuestionSeen: null,
    });

    router.push(
      `/rv-search?${querystring.stringify(
        weekend
          ? {
              [ESearchFilters.DATE_FROM]: dayjs().weekday(6).format('YYYY-MM-DD'),
              [ESearchFilters.DATE_TO]: dayjs().weekday(6).add(1, 'day').format('YYYY-MM-DD'),
              [ESearchFilters.SORT]: 'distance',
            }
          : {},
      )}`,
      undefined,
      { shallow: true },
    );
  };

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        <Heading variant={EHeadingStyleVariant.H4} className="mb-4 text-center">
          {hasNoWeekendRentals
            ? intl.formatMessage({ defaultMessage: 'Discover rentals', id: '6HkF1E' })
            : intl.formatMessage({ defaultMessage: 'Weekend rentals near you', id: 'C+9SVZ' })}
        </Heading>

        <RentalsCarousel
          rentalsList={(() => {
            if (rentalsList.length) return rentalsList;
            if (randomRentalsList.length) return randomRentalsList;
            return Array.from({ length: 9 }, (_, i) => i).map(() => ({ loading: true }));
          })()}
          eventSource={eventSource}
          carouselAlgoSlug={EAlgoSlugVariationKey.weekender}
          transactionalAppearanceEnabled
        />

        {(!!rentalsList.length || !!randomRentalsList.length) && (
          <div className="flex justify-center mt-4">
            <Button
              label={
                rentalsList.length
                  ? intl.formatMessage({
                      defaultMessage: 'View all weekend rentals',
                      id: '7nMPWe',
                    })
                  : intl.formatMessage({
                      defaultMessage: 'View all rentals',
                      id: '6nZmbk',
                    })
              }
              onClick={() => handleViewAllRentals(!!rentalsList.length)}
              className="w-full md:w-auto"
            />
          </div>
        )}
      </div>
    </div>
  );
};
