import { EHeadingStyleVariant, Heading } from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HomeSearchWidget } from '@/components/ui/HomeSearchWidget';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useDefaultHomePageAndMetaValues from '@/hooks/useDefaultHomePageAndMetaValues';

import { PropositionValues } from './PropositionValues';

export const BannerSection = () => {
  const { isMobile } = useBreakpoint();

  const { homepageBanner, homepageBannerMobile } = useDefaultHomePageAndMetaValues();

  return (
    <div className="relative flex flex-col lg:min-h-[100vh]">
      <div className="relative flex flex-col flex-grow">
        <div className="absolute inset-0">
          <Image
            src={isMobile ? homepageBannerMobile : homepageBanner}
            alt="Homepage banner"
            role="presentation"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            quality={45}
            priority
          />

          <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,0,0,0.65)] to-[rgba(0,0,0,0)]" />
        </div>

        <div className="relative flex flex-col justify-center flex-grow px-4 pt-24 pb-8 lg:pb-24 lg:px-10 gap-6 lg:gap-8">
          <div
            className="flex flex-col px-2 text-center text-white gap-2"
            data-testid="home-header">
            <Heading variant={EHeadingStyleVariant.H3}>
              <FormattedMessage
                defaultMessage="Your next Outdoorsy adventure starts here"
                id="kHLpwr"
              />
            </Heading>

            <Heading variant={EHeadingStyleVariant.H6}>
              <FormattedMessage
                defaultMessage="Discover the best RV, camper van or travel trailer rental for your next vacation."
                id="dHKjnc"
              />
            </Heading>
          </div>

          <div className="relative flex flex-col items-center lg:mb-32">
            <HomeSearchWidget />
          </div>
        </div>
      </div>

      <PropositionValues />
    </div>
  );
};
