import KEY from '../constants/key';

interface IGetAllInteractiveElements {
  container: HTMLElement;
  focusableElements?: string[];
  restrictions?: string[];
}

export const getAllInteractiveElements = ({
  container,
  focusableElements = ['a[href]', 'button', 'textarea', 'input', 'select', 'iframe', '[tabindex]'],
  restrictions = [':not([disabled])', ':not([tabindex="-1"])'],
}: IGetAllInteractiveElements): HTMLElement[] => {
  const INTERACTIVE_ELEMENTS = focusableElements
    .map(item => `${item}${restrictions.join('')}`)
    .join(', ');

  const elementsArray = Array.from(container.querySelectorAll<HTMLElement>(INTERACTIVE_ELEMENTS));

  const interactiveAndVisibleElements = elementsArray.filter((item: HTMLElement) => {
    const style = typeof window !== 'undefined' && window.getComputedStyle(item);
    return style && style.display !== 'none';
  });

  return interactiveAndVisibleElements;
};

interface IFocusableElements {
  elementsCount: number;
  currentIndex: number;
  nextIndex: number;
  previousIndex: number;
  activeElement: HTMLElement;
  firstElement: HTMLElement;
  lastElement: HTMLElement;
  currentElement: HTMLElement;
  nextElement: HTMLElement;
  previousElement: HTMLElement;
  allElements: HTMLElement[];
  isActiveElementPartOfAllElements: boolean;
}

interface IHasPressedProps {
  ENTER: boolean;
  ESCAPE: boolean;
  SPACE: boolean;
  DOWN: boolean;
  UP: boolean;
  RIGHT: boolean;
  LEFT: boolean;
  TAB: boolean;
  HOME: boolean;
  END: boolean;
}

interface IGetFocusableElements {
  current?: HTMLElement;
  focusableElements?: HTMLElement[];
  container: HTMLElement;
}

export const getOnFocusableElements = ({
  current,
  focusableElements,
  container,
}: IGetFocusableElements): IFocusableElements => {
  const activeElement = current || (document.activeElement as HTMLElement);
  const allElements = focusableElements
    ? focusableElements
    : getAllInteractiveElements({
        container,
      });
  const elementsCount = allElements.length;
  const firstElement = allElements[0];
  const lastElement = allElements.slice(-1)[0];
  const isActiveElementPartOfAllElements = allElements.includes(activeElement);
  const currentIndex = allElements.indexOf(activeElement);
  const nextIndex = (currentIndex + 1) % allElements.length;
  const previousIndex = (currentIndex + allElements.length - 1) % allElements.length;
  const currentElement = allElements[currentIndex];
  const nextElement = allElements[nextIndex];
  const previousElement = allElements[previousIndex];

  return {
    elementsCount,
    currentIndex,
    nextIndex,
    previousIndex,
    activeElement,
    // @ts-expect-error fixable: unchecked index access
    firstElement,
    // @ts-expect-error fixable: unchecked index access
    lastElement,
    allElements,
    // @ts-expect-error fixable: unchecked index access
    nextElement,
    // @ts-expect-error fixable: unchecked index access
    previousElement,
    // @ts-expect-error fixable: unchecked index access
    currentElement,
    isActiveElementPartOfAllElements,
  };
};

export const hasPressed = (e: KeyboardEvent | React.KeyboardEvent): IHasPressedProps => ({
  ENTER: e.key === KEY.ENTER,
  ESCAPE: e.key === KEY.ESCAPE,
  SPACE: e.key === KEY.SPACE,
  DOWN: e.key === KEY.DOWN,
  UP: e.key === KEY.UP,
  RIGHT: e.key === KEY.RIGHT,
  LEFT: e.key === KEY.LEFT,
  TAB: e.key === KEY.TAB,
  HOME: e.key === KEY.HOME,
  END: e.key === KEY.END,
});
