import { stringify } from 'query-string';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { ESearchFilters } from '@/constants/searchFilters';
import { useRouter } from '@/hooks/useRouter';
import { getUserFavorites } from '@/redux/selectors/favorites';
import apiRequest from '@/services/apiRequest';
import { IData as IRentalData } from '@/services/types/search/rentals/id';
import { getSearchApi } from '@/utility/getCoreApi';
import { mapRentalsToTiles } from '@/utility/mapSearchResultToTile';

type TRandomRentalsResponse = {
  data: IRentalData[];
};

export const useRandomRentalsTiles = (skip: boolean) => {
  const router = useRouter();

  const userFavorites = useSelector(getUserFavorites);

  const { data: randomRentalsResponse } = useSWR<TRandomRentalsResponse>(
    skip
      ? null
      : `${getSearchApi()}/rentals/random?${stringify({
          [ESearchFilters.PAGE_LIMIT]: 9,
          [ESearchFilters.RAW_JSON]: true,
        })}`,
    (url: string) => apiRequest<TRandomRentalsResponse>({ url }),
  );

  return useMemo(
    () =>
      mapRentalsToTiles({
        rentals: randomRentalsResponse?.data || [],
        favorites: userFavorites,
        queryParams: router.query,
      }),
    [randomRentalsResponse?.data, userFavorites, router.query],
  );
};
