import React from 'react';
import { useIntl } from 'react-intl';

import Divider, { DividerTone } from '@/components/switchback/Divider/Divider';
import Heading from '@/components/switchback/Heading/Heading';
import Text from '@/components/switchback/Text/Text';
import { TSeoHomeContentItem, TSeoHomeSection } from '@/services/getHomePageData';

import css from './SeoLinks.module.css';

interface ISEOLinksProps {
  seoData: TSeoHomeSection[];
}

export const SeoLinks: React.FC<ISEOLinksProps> = ({ seoData }: ISEOLinksProps) => {
  return (
    <div className={`${css.seoWrapper} ${css.homeWidth}`}>
      {seoData.map((section, index) => (
        <SeoLinksSection key={index} section={section} />
      ))}
    </div>
  );
};

interface ISectionProps {
  section: TSeoHomeSection;
}

enum SeoSectionType {
  RV = 'rv',
  PARK = 'park',
}

const SeoLinksSection: React.FC<ISectionProps> = ({ section }: ISectionProps) => {
  let seoSectionType = SeoSectionType.RV;
  let className = css.seoRvArticlesWrapper;
  let renderDivider = false;

  if (section.type !== 'nearby-content') {
    seoSectionType = SeoSectionType.PARK;
    className = '';
    renderDivider = true;
  }

  if (section.data.length === 0) {
    return null;
  }

  return (
    <div className={css.seoSectionWrapper}>
      {renderDivider && <Divider tone={DividerTone.dark} className="mb-8" />}
      {section.title && (
        <Heading level={3} className={css.seoSectionTitle}>
          {section.title}
        </Heading>
      )}
      <div className={className}>
        {section.data.map((seoContent, seoIndex) =>
          section.type === 'park-links' ? (
            <div key={seoIndex} className={css.rent}>
              <SeoLink seoItem={seoContent} seoSectionType={seoSectionType} />
            </div>
          ) : (
            <SeoLink key={seoIndex} seoItem={seoContent} seoSectionType={seoSectionType} />
          ),
        )}
      </div>
      {renderDivider && <Divider tone={DividerTone.dark} className="mt-8" />}
    </div>
  );
};

interface IProps {
  seoItem: TSeoHomeContentItem;
  seoSectionType: SeoSectionType;
}

const SeoLink: React.FC<IProps> = ({ seoItem, seoSectionType }: IProps) => {
  const intl = useIntl();
  const titleStyle = seoSectionType === SeoSectionType.RV ? 'font-medium' : 'py-2';

  return (
    <a className={css.article} href={`${process.env.hostApp}/${seoItem.path}`}>
      <Heading level={4} className={titleStyle}>
        {seoItem.display}
      </Heading>
      {!!seoItem.count && (
        <Text type="paragraph">
          {intl.formatMessage(
            {
              defaultMessage: '{count, plural,one {# RV rental} other {# RV rentals}}',
              id: '03gRRd',
            },
            { count: seoItem.count },
          )}
        </Text>
      )}
    </a>
  );
};
