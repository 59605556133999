import React, { createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSearchLocationPopularDestinations } from '@/constants/search';
import { usePopularParks } from '@/graphql/hooks/search/getPopularParks';
import useMapboxQuery from '@/hooks/useMapboxQuery';
import { useRecentSearchesWithFilters } from '@/hooks/useRecentSearchesWithFilters';
import { setHeaderSearchFilter } from '@/redux/modules/globalHeader';
import { getHeaderSearchAddressFilter } from '@/redux/selectors/globalHeader';

import { useNavigateToRecentSearch } from './recentSearch';

interface ISuggestion {
  label: string;
  value?: unknown;
  url?: string;
}

type TLocationFilterContext = {
  addressValue: string;
  setAddressValue: (value: string) => void;
  suggestions: ISuggestion[];
  recentSearches: ISuggestion[];
  popularDestinations: ISuggestion[];
  navigateToRecentSearch: (url: string) => void;
};

const LocationFilterContext = createContext<TLocationFilterContext>({
  addressValue: '',
  setAddressValue: () => undefined,
  suggestions: [],
  recentSearches: [],
  popularDestinations: [],
  navigateToRecentSearch: () => undefined,
});

export const useLocationFilterContext = () => useContext(LocationFilterContext);

const useSuggestions = (query: string) => {
  const places = useMapboxQuery(
    query.length < 3
      ? null
      : {
          text: query,
          types: 'country,region,postcode,district,place,locality,neighborhood,address',
        },
  );

  return places.map(p => ({ label: p.place_name, value: p }));
};

const usePopularDestinations = () => {
  const searchLocationPopularDestinations = useSearchLocationPopularDestinations();
  const parksResponse = usePopularParks(searchLocationPopularDestinations);

  return parksResponse?.length
    ? parksResponse?.map(park => ({ label: park?.label }))
    : searchLocationPopularDestinations;
};

type TLocationFilterProviderProps = {
  onNavigateToRecentSearch?: () => void;
};

export const LocationFilterProvider: React.FC<
  React.PropsWithChildren<TLocationFilterProviderProps>
> = ({ onNavigateToRecentSearch, children }) => {
  const dispatch = useDispatch();

  const addressValue = useSelector(getHeaderSearchAddressFilter) || '';

  const setAddressValue = (value: string) => {
    dispatch(setHeaderSearchFilter({ address: value }));
  };

  const suggestions = useSuggestions(addressValue);
  const [recentSearches] = useRecentSearchesWithFilters();
  const popularDestinations = usePopularDestinations();

  const navigateToRecentSearch = useNavigateToRecentSearch(onNavigateToRecentSearch);

  return (
    <LocationFilterContext.Provider
      value={{
        addressValue,
        setAddressValue,
        suggestions,
        recentSearches,
        popularDestinations,
        navigateToRecentSearch,
      }}>
      {children}
    </LocationFilterContext.Provider>
  );
};
