import {
  Card,
  ECardBorderRadiusSize,
  ECardMediaSize,
  ETextStyleVariant,
  Text,
} from '@outdoorsyco/bonfire';
import Image from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';

import listCampgroundImage from '@/assets/home/become-a-5-star-host/home_5-stars_campground.jpg';
import listRVImage from '@/assets/home/become-a-5-star-host/home_5-stars_RV.jpg';
import Heading from '@/components/switchback/Heading/Heading';

import css from './BecomeA5StarHost.module.css';

export const BecomeA5StarHost: React.FC = () => {
  const intl = useIntl();

  const listings = [
    {
      title: intl.formatMessage({ defaultMessage: 'List your RV', id: 'eRWzNe' }),
      image: listRVImage,
      url: '/become-a-host/rv',
    },
    {
      title: intl.formatMessage({ defaultMessage: 'List your campground', id: '2RvIth' }),
      image: listCampgroundImage,
      url: '/become-a-host/campground',
    },
  ];

  return (
    <div className={css.wrapper}>
      <Heading level={2} className={css.title}>
        {intl.formatMessage({ defaultMessage: 'Become a 5-star host', id: 'dPjAqV' })}
      </Heading>

      <div className={css.listings}>
        {listings.map(listing => (
          <a key={listing.url} href={listing.url}>
            <Card
              borderRadiusSize={ECardBorderRadiusSize.Large}
              withTransparentBackground
              withBoxShadow={false}>
              <Card.Media size={ECardMediaSize.Medium} fullRadius className="overflow-clip">
                <Image
                  alt=""
                  src={listing.image}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                />
              </Card.Media>
              <Card.Content className="px-4 pt-2 pb-4 text-center">
                <Text variant={ETextStyleVariant.LargeBold}>{listing.title}</Text>
              </Card.Content>
            </Card>
          </a>
        ))}
      </div>
    </div>
  );
};
