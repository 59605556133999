import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DELIVERY, PEOPLE, SHIELD_CHECK, STAR_OUTLINE } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';

export const PropositionValues = () => {
  const features = [
    {
      icon: STAR_OUTLINE,
      text: <FormattedMessage defaultMessage="1 million customers and counting" id="nqpkrT" />,
      className: 'order-3 lg:order-1',
    },
    {
      icon: PEOPLE,
      text: <FormattedMessage defaultMessage="Real people here to help 24/7" id="I/HR3P" />,
      className: 'order-2',
    },
    {
      icon: SHIELD_CHECK,
      text: <FormattedMessage defaultMessage="24/7 roadside assistance & insurance" id="Cd/21r" />,
      className: 'order-1 lg:order-3',
    },
    {
      icon: DELIVERY,
      text: <FormattedMessage defaultMessage="Get it delivered" id="NZeWM0" />,
      className: 'order-4',
    },
  ];

  return (
    <div className="flex justify-center py-6">
      <div className="flex flex-col justify-center lg:flex-wrap lg:flex-row lg:items-center gap-x-8 gap-y-2">
        {features.map((feature, index) => (
          <Text
            key={index}
            variant={ETextStyleVariant.SmallRegular}
            className={cn('flex items-center gap-3 shrink-0', feature.className)}>
            <Icon name={feature.icon} size={IconSize.normal} />
            {feature.text}
          </Text>
        ))}
      </div>
    </div>
  );
};
