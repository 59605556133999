import { Button, EButtonColorVariant, EHeadingStyleVariant, Heading } from '@outdoorsyco/bonfire';
import Image, { StaticImageData } from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';

import ODNLogo from '@/assets/home/ODN_logo.svg';
import Link from '@/components/switchback/Link/Link';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';

interface IDestinationCardProps {
  imageSrc: string | StaticImageData;
  url: string;
  name: string;
  wpURL: string;
}
export const DestinationCard = ({ name, url, imageSrc, wpURL }: IDestinationCardProps) => {
  const intl = useIntl();
  const isODNtileTestEnabled = useExperimentIsEnabled(OptimizelyFlags.ODN_TILE_TEST);

  const destinationURL = isODNtileTestEnabled ? wpURL : url;

  return (
    <Link href={destinationURL} passHref legacy forceClientsideRouting={!isODNtileTestEnabled}>
      <a
        href={destinationURL}
        className="relative flex flex-col items-center justify-between text-white h-92 min-w-[280px]"
        {...(isODNtileTestEnabled && { target: '_blank', rel: 'noreferrer' })}>
        <Image src={imageSrc} alt={name} layout="fill" objectFit="cover" className="rounded-xl" />

        <div className="z-10 w-full mt-8">
          <div className="flex justify-center mb-2">
            <ODNLogo />
          </div>
          <Heading variant={EHeadingStyleVariant.H6} className="uppercase">
            {name}
          </Heading>
        </div>

        <Button
          className="z-10 mb-8"
          label={intl.formatMessage({ defaultMessage: 'Book now', id: '1mLe+3' })}
          variant={EButtonColorVariant.Tertiary}
          onDark
        />
      </a>
    </Link>
  );
};
