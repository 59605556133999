import { Settings } from 'react-slick';

import { breakpoint } from '@/constants/breakpoint';

export type TSettings = {
  [key in keyof Settings]?: Settings[key] | Array<Settings[key]>;
};

export const formatLibProps = (props: TSettings) => {
  const libBreakpoints = [breakpoint.md, breakpoint.lg];

  // @ts-expect-error fixable: unchecked index access
  return Object.entries(props).reduce((acc: Settings, curr) => {
    const [prop, value] = curr;

    if (Array.isArray(value)) {
      /* Default values */
      const defaultValue = value.slice(-1)[0];
      const defaultSettings = { ...acc, [prop]: defaultValue };

      /* Responsive values */
      const responsiveValues: any[] = value.slice(0, -1);
      const responsive = responsiveValues.map((item: any, index: number) => {
        !acc.responsive && (acc.responsive = []);
        !acc.responsive[index] && (acc.responsive[index] = { breakpoint: 0, settings: {} });

        return {
          breakpoint: libBreakpoints[index],
          settings: {
            ...(acc.responsive[index].settings as Settings),
            [prop]: item,
          },
        };
      });

      return { ...acc, ...defaultSettings, responsive };
    }

    return { ...acc, [prop]: value };
  }, {});
};

export const changeStylePerBreakpoint = (prop: any, set: any, windowSize: any) => {
  const breakpoints = [breakpoint.sm, breakpoint.md, breakpoint.lg, breakpoint.xxxl];
  if (Array.isArray(prop)) {
    prop.map((item, index) => {
      // @ts-expect-error fixable: unchecked index access
      const stylePerBrakpoint = { [breakpoints[index]]: item };
      if (breakpoints[index] && windowSize?.width && windowSize.width > breakpoints[index]) {
        // @ts-expect-error fixable: unchecked index access
        set(stylePerBrakpoint[breakpoints[index]]);
      }
    });
  }
};
