import image1 from '@/assets/home/7-million-nights/7-million-nights_1.jpg';
import image2 from '@/assets/home/7-million-nights/7-million-nights_2.jpg';
import image3 from '@/assets/home/7-million-nights/7-million-nights_3.jpg';
import image4 from '@/assets/home/7-million-nights/7-million-nights_4.jpg';

export const pressQuotes = [
  { quote: '“Road trips are our new family tradition.”', author: 'Matt B' },
  { quote: '“Time spent outdoors was just what our family needed.”', author: 'Fernández family' },
  {
    quote: '“We’d never had an RV delivered - Outdoorsy and our host made it so easy!”',
    author: 'Samantha T',
  },
];

export const instagramImages = [image1, image2, image3, image4];
