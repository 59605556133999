import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { IRentalTile, mapRentalsToTiles } from '@/utility/mapSearchResultToTile';

type TSimilarRentalsData = TRootState['similarRentals']['data'];

export const getSimilarRentalsTiles = createSelector<
  TRootState,
  ReturnType<typeof getUserFavorites>,
  TSimilarRentalsData,
  TRootState['queryParams'],
  IRentalTile[]
>(
  getUserFavorites,
  state => {
    const rentalsData = isEmpty(state.similarRentals.data)
      ? state.rentals.rentals
      : state.similarRentals.data;
    return rentalsData;
  },
  state => state.queryParams,
  (favorites, data, queryParams) => mapRentalsToTiles({ rentals: data, favorites, queryParams }),
);

export const getRentalsTiles = createSelector<
  TRootState,
  ReturnType<typeof getUserFavorites>,
  TSimilarRentalsData,
  TRootState['queryParams'],
  IRentalTile[]
>(
  getUserFavorites,
  state => state.rentals.rentals,
  state => state.queryParams,
  (favorites, data, queryParams) => mapRentalsToTiles({ rentals: data, favorites, queryParams }),
);

export const getRentalsAreFetched = createSelector<TRootState, boolean, boolean>(
  state => state.rentals.isFetched,
  isFetched => isFetched,
);
