import { createSelector } from 'reselect';

import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';
import { itemizeAddress } from '@/utility/itemizeAddress';

import { TRootState } from '../rootReducer';

const getMapboxPlace = (state: TRootState) => state.mapboxPlace.data;

export const getDeliveryCenter = createSelector([getMapboxPlace], (place): string | undefined => {
  if (place?.center && Array.isArray(place.center) && place.center.length === 2) {
    return JSON.stringify(place?.center || []);
  }
  return undefined;
});

export const getDeliveryDetails = createSelector([getMapboxPlace], (place): string | undefined => {
  const itemizedAddress: IQuoteDeliveryLocation = place ? itemizeAddress(place) : {};
  const { state, country, city, street, zip } = itemizedAddress;
  if (!country || !state) return undefined;
  return encodeURIComponent(
    JSON.stringify({
      country,
      state,
      city,
      street,
      zip,
    }),
  );
});
