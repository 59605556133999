import {
  Dropdown,
  ESelectorVariants,
  ETextStyleVariant,
  Selector,
  SelectorGroup,
  SelectorType,
  Text,
} from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';

import OptionsInput from '@/components/switchback/OptionsInput/OptionsInput';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { EVehicleType, setHeaderSearchFilter } from '@/redux/modules/globalHeader';
import {
  getHeaderSearchVehicleFilter,
  getHeaderSearchVehicleTypeFilter,
} from '@/redux/selectors/globalHeader';
import apiRequest from '@/services/apiRequest';
import { trackEvent } from '@/services/track-event';
import { IVehicle, IVehicleMake, IVehicleYear } from '@/services/types/search/vehicle';
import { getCoreApi } from '@/utility/getCoreApi';

import { FilterContent, TFilterContentSharedProps } from './FilterContent';

type TVehicleTypeFilterContentProps = TFilterContentSharedProps;

export const VehicleTypeFilterContent = (props: TVehicleTypeFilterContentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { isAboveDesktop } = useBreakpoint();

  const vehicleType = useSelector(getHeaderSearchVehicleTypeFilter) || EVehicleType.ALL;

  const handleVehicleTypeChange = (selection: EVehicleType) => {
    dispatch(
      setHeaderSearchFilter({
        vehicleType: selection,
      }),
    );

    trackEvent({
      event: 'search/filter/vehicle-type',
      action: 'update',
      before: vehicleType,
      after: selection,
    });
  };

  const selectorsVariant = isAboveDesktop ? ESelectorVariants.Default : ESelectorVariants.Radio;

  return (
    <FilterContent
      header={
        <>
          {intl.formatMessage({
            defaultMessage: 'Vehicle type',
            id: 'Ksn7Q6',
            description: 'Home Search Widget > Vehicle Type Modal Title',
          })}
        </>
      }
      content={
        <div className="flex flex-col gap-10">
          <SelectorGroup
            ariaLabel={intl.formatMessage({
              defaultMessage: 'Select vehicle type',
              id: 'SBiBVJ',
              description: 'Home Search Widget > Vehicle Type Filter Aria Label',
            })}
            onChange={value => handleVehicleTypeChange(value as EVehicleType)}
            selectorType={SelectorType.Single}
            value={vehicleType}>
            <div className="grid lg:grid-cols-4 gap-6">
              <Selector
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Show me all listings',
                  id: '7RXrSZ',
                  description: 'Home Search Widget > Vehicle Type Filter All Option',
                })}
                label={intl.formatMessage({
                  defaultMessage: 'Show me all listings',
                  id: '7RXrSZ',
                  description: 'Home Search Widget > Vehicle Type Filter All Option',
                })}
                name={EVehicleType.ALL}
                value={EVehicleType.ALL}
                icon="General.Search"
                variant={selectorsVariant}
                contentWrapperClassName="h-full"
              />

              <Selector
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Drivable',
                  id: 'RzX/xV',
                  description: 'Home Search Widget > Vehicle Type Filter Drivable Option',
                })}
                label={intl.formatMessage({
                  defaultMessage: 'Drivable',
                  id: 'RzX/xV',
                  description: 'Home Search Widget > Vehicle Type Filter Drivable Option',
                })}
                name={EVehicleType.DRIVABLE}
                value={EVehicleType.DRIVABLE}
                icon="Vehicles.Vehicles.CamperVan.Simple"
                variant={selectorsVariant}
                contentWrapperClassName="h-full"
              />

              <Selector
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Towable',
                  id: 'm/6iOk',
                  description: 'Home Search Widget > Vehicle Type Filter Towable Option',
                })}
                label={intl.formatMessage({
                  defaultMessage: 'Towable',
                  id: 'm/6iOk',
                  description: 'Home Search Widget > Vehicle Type Filter Towable Option',
                })}
                name={EVehicleType.TOWABLE}
                value={EVehicleType.TOWABLE}
                icon="Vehicles.Vehicles.TravelTrailer.Simple"
                variant={selectorsVariant}
                contentWrapperClassName="h-full"
              />

              {!isAboveDesktop && vehicleType === EVehicleType.TOWABLE && <VehicleSelector />}

              <Selector
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Set up',
                  id: 'Y8qfwQ',
                  description: 'Home Search Widget > Vehicle Type Filter Deliverables Option',
                })}
                label={intl.formatMessage({
                  defaultMessage: 'Set up',
                  id: 'Y8qfwQ',
                  description: 'Home Search Widget > Vehicle Type Filter Deliverables Option',
                })}
                name={EVehicleType.DELIVERABLES}
                value={EVehicleType.DELIVERABLES}
                icon="Amenities.Awning.Awning"
                variant={selectorsVariant}
                contentWrapperClassName="h-full"
              />
            </div>
          </SelectorGroup>

          {isAboveDesktop && vehicleType === EVehicleType.TOWABLE && <VehicleSelector />}
        </div>
      }
      {...props}
    />
  );
};

const VehicleSelector = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const vehicle = useSelector(getHeaderSearchVehicleFilter);

  const [vehicleYear, setVehicleYear] = useState(vehicle?.year?.toString());
  const [vehicleMake, setVehicleMake] = useState(vehicle?.make);
  const [vehicleModel, setVehicleModel] = useState(vehicle?.id?.toString());

  const { data: vehicleYearsData } = useSWR(`${getCoreApi()}/towing_info`, url =>
    apiRequest<IVehicleYear[]>({ url }, true),
  );

  const { data: vehicleMakeData } = useSWR(
    vehicleYear ? `${getCoreApi()}/towing_info?year=${vehicleYear}` : null,
    url => apiRequest<IVehicleMake[]>({ url }, true),
  );

  const { data: vehicleModelData } = useSWR(
    vehicleYear && vehicleMake
      ? `${getCoreApi()}/towing_info?year=${vehicleYear}&make=${vehicleMake}`
      : null,
    url => apiRequest<IVehicle[]>({ url }, true),
  );

  const handleVehicleChange = (selection?: IVehicle) => {
    if (!selection) return;

    dispatch(
      setHeaderSearchFilter({
        vehicle: selection,
      }),
    );

    trackEvent({
      event: 'search/filter/vehicle',
      action: 'update',
      before: vehicle && `${vehicle.make}, ${vehicle.model}`,
      after: `${selection.make}, ${selection.model}`,
    });
  };

  return (
    <div className="flex flex-col mb-6 gap-6 lg:mb-0">
      <Text variant={ETextStyleVariant.LargeRegular}>
        {intl.formatMessage({
          defaultMessage: 'Tell us about your towing vehicle to see what you can tow.',
          id: 'XgirlV',
        })}
      </Text>

      <div className="grid lg:grid-cols-3 gap-6">
        <DropdownSelector
          name="vehicle-year"
          label={intl.formatMessage({ defaultMessage: 'Year', id: 'IFo1oo' })}
          inputValue={vehicleYear || ''}
          onChange={year => {
            setVehicleYear(year);
            setVehicleMake(undefined);
            setVehicleModel(undefined);
          }}
          options={vehicleYearsData?.map(({ year }) => ({
            value: year.toString(),
            label: year.toString(),
          }))}
        />

        <DropdownSelector
          name="vehicle-make"
          label={intl.formatMessage({ defaultMessage: 'Make', id: '6AAM0P' })}
          inputValue={vehicleMake || ''}
          onChange={make => {
            setVehicleMake(make);
            setVehicleModel(undefined);
          }}
          options={vehicleMakeData?.map(({ make }) => ({
            value: make,
            label: make,
          }))}
          disabled={!vehicleYear}
        />

        <DropdownSelector
          name="vehicle-model"
          label={intl.formatMessage({ defaultMessage: 'Model', id: 'rhSI1/' })}
          inputValue={vehicleModel || ''}
          onChange={model => {
            setVehicleModel(model);
            handleVehicleChange(vehicleModelData?.find(({ id }) => id.toString() === model));
          }}
          options={vehicleModelData?.map(({ id, model }) => ({
            value: id.toString(),
            label: model,
          }))}
          disabled={!vehicleMake}
        />
      </div>
    </div>
  );
};

type TDropdownSelectorProps = {
  name: string;
  label: string;
  inputValue: string;
  onChange: (value: string) => void;
  options?: { value: string; label: string }[];
  disabled?: boolean;
};

// We have issues with bonfire dropdown inside the menu, since we close it
// on outside click, and dropdown menu is rendered outside. But it works just fine for mobile.
// Keep using switchback dropdown for desktop, and bonfire for mobile.
const DropdownSelector = ({
  name,
  label,
  inputValue,
  onChange,
  options,
  disabled,
}: TDropdownSelectorProps) => {
  const intl = useIntl();

  const { isAboveDesktop } = useBreakpoint();

  if (isAboveDesktop) {
    return (
      <OptionsInput
        name={name}
        placeholder={label}
        value={inputValue}
        selectedOption={options?.find(option => option.value === inputValue)}
        valueClassName="overflow-hidden whitespace-nowrap overflow-ellipsis"
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        options={options}
      />
    );
  }

  return (
    <Dropdown
      label={label}
      ariaLabel={label}
      clearSelectionOptions={{
        ariaLabel: intl.formatMessage({
          defaultMessage: 'Clear selection',
          id: 'EYIw2M',
        }),
      }}
      inputValue={inputValue}
      onChange={onChange}
      disabled={disabled}
      searchEnabled
      className="overflow-hidden">
      {options?.map(option => (
        <Dropdown.Option
          key={option.value}
          value={option.value}
          content={{
            label: option.label,
          }}
        />
      )) || []}
    </Dropdown>
  );
};
