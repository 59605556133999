import React from 'react';
import { useIntl } from 'react-intl';

import Bayfield from '@/assets/home/bayfield.jpg';
import HillCountry from '@/assets/home/hill-country.jpg';
import Yosemite from '@/assets/home/yosemite.jpg';
import { isProdDeploy } from '@/utility/isSSR';

import { DestinationCard } from './DestinationCard';

export const DestinationCarousel = () => {
  const intl = useIntl();

  const isProd = isProdDeploy();

  const destinations = [
    {
      name: intl.formatMessage({ defaultMessage: 'Hill country, TX', id: 'lQ1Tzr' }),
      imageSrc: HillCountry,
      url: isProd
        ? '/campground-rental/stonewall_tx/outdoorsy-hill-country_1-listing?cam=homepage_odn'
        : '/campground-rental/fredericksburg_tx/outdoorsy-hill-country_12-listing',
      wpURL: isProd
        ? 'https://www.outdoorsy.com/destinations/outdoorsy-hill-country'
        : 'https://www.staging.outdoorsy.com/destinations/outdoorsy-hill-country',
    },
    {
      name: intl.formatMessage({ defaultMessage: 'Yosemite, CA', id: 'zPAccX' }),
      imageSrc: Yosemite,
      url: isProd
        ? '/campground-rental/bass-lake_ca/outdoorsy-yosemite_2-listing?cam=homepage_odn'
        : '/campground-rental/bass-lake_ca/bass-lake-at-yosemite_10-listing',
      wpURL: isProd
        ? 'https://www.outdoorsy.com/destinations/outdoorsy-yosemite'
        : 'https://www.staging.outdoorsy.com/destinations/outdoorsy-yosemite',
    },
    {
      name: intl.formatMessage({ defaultMessage: 'Bayfield, CO', id: '3G07F9' }),
      imageSrc: Bayfield,
      url: isProd
        ? '/campground-rental/bayfield_co/outdoorsy-bayfield_3-listing?cam=homepage_odn'
        : '/campground-rental/bayfield_co/training-outdoorsy-bayfield_13-listing',
      wpURL: isProd
        ? 'https://www.outdoorsy.com/destinations/outdoorsy-bayfield'
        : 'https://www.staging.outdoorsy.com/destinations/outdoorsy-bayfield',
    },
  ];

  return (
    <div className="px-6 pb-4 -mx-6 overflow-auto lg:mx-0 lg:px-0 grid grid-flow-col gap-4 lg:gap-6">
      {destinations.map(({ name, imageSrc, url, wpURL }, index) => (
        <DestinationCard key={index} imageSrc={imageSrc} url={url} name={name} wpURL={wpURL} />
      ))}
    </div>
  );
};
