import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

type TFilterButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  value: string;
};

export const FilterButton = ({ label, value, className, ...props }: TFilterButtonProps) => {
  return (
    <button
      type="button"
      {...props}
      className={cn(
        'flex flex-col gap-2 px-4 py-2 w-full border border-neutral-20 rounded-lg text-left',
        'lg:py-4 lg:border-none lg:rounded-none lg:overflow-hidden',
        className,
      )}>
      <Text
        variant={ETextStyleVariant.Label}
        component="span"
        className="w-full truncate text-gray-50">
        {label}
      </Text>

      <Text
        variant={ETextStyleVariant.MediumBold}
        component="span"
        className="w-full text-gray-800 truncate">
        {value}
      </Text>
    </button>
  );
};
